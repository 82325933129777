import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";
import CreateInvoice from "../../../components/Modal/Admin/Invoicing/CreateInvoicing";
import ViewInvoice from "../../../components/Modal/Admin/Invoicing/ViewInvoice";
import EditInvoicing from "../../../components/Modal/Admin/Invoicing/EditInvoicing";
import AddCustomServicePayment from "../../../components/Modal/Admin/Invoicing/AddCustomServicePayment";
import ViewCustomFixedPayHistory from "../../../components/Modal/Admin/Invoicing/ViewCustomFixedPayHistory";
import { Button, Modal, Spinner } from "react-bootstrap";


export default function Invoicing() {

    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const [showManageModal, setShowManageModal] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false); // State for EditModal
    const [showViewModal, setShowViewModal] = useState(false); // State for EditModal
    const [selectedItem, setSelectedItem] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [isGeneralLoading, setIsGeneralLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [type, setType] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [paymentHistories, setPaymentHistory] = useState(null);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);

    const [percentageModalOpen, setPercentageModalOpen] = useState(false);
    const [percentage, setPercentage] = useState('');
    const [currentAccountId, setCurrentAccountId] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            setIsGeneralLoading(true);

            try {
                const response = await axiosInstance.get(`user/invoice_custom_service/fetch`, {
                    params: {
                        page,
                        filter: searchQuery,
                        start_date: startDate, // Pass start date
                        end_date: endDate,     // Pass end date
                        status: statusFilter,
                        type: type,
                    },
                });
                setItems(response.data.data.invoices.data);

                setPagination(response.data.data.invoices);
                // console.log(response.data.data.invoices.data);

            } catch (error) {
                console.error("Error fetching items:", error);
            } finally {
                setIsGeneralLoading(false);
            }
        };

        fetchItems();
    }, [page, refreshKey, searchQuery, startDate, type, endDate, statusFilter]);

    const handleShowManageModal = (item) => {
        setSelectedItem(item);
        setShowManageModal(true);
    };

    const handleShowEditModal = (item) => {
        setSelectedItem(item);
        setShowEditModal(true);
    };
    const handleShowPayment = (item) => {
        setSelectedItem(item);
        setShowPayment(true);
    };

    const handleShowViewInvoice = (item) => {
        setSelectedItem(item);
        setShowViewModal(true);
    };

    const handleShowPaymentHistory = (item) => {
        setPaymentHistory(item);
        setShowPaymentHistory(true);
    };


    const handleCloseViewPaymentHistory = () => {
        setShowPaymentHistory(false);
        setPaymentHistory(null);
    };

    const MySwal = withReactContent(Swal);

    const handleDelete = async (account_id) => {
        const result = await MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.post(`user/invoice_custom_service/delete/${account_id}`);
                toast.success("Invoice deleted successfully!");
                handleRefresh();
            } catch (error) {
                console.error("Error deleting Invoice:", error);
                toast.error("Error deleting Invoice.");
            }
        }
    };

    const handleCloseManageModal = () => {
        setShowManageModal(false);
        setSelectedItem(null);
    };

    const handleClosePayment = () => {
        setShowPayment(false);
        setSelectedItem(null);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedItem(null);
    };
    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedItem(null);
    };

    const handleRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    // Function to format amount with commas
    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const groupedItems = items.reduce((acc, item) => {
        const customerId = item.account.id;

        if (!acc[customerId]) {
            acc[customerId] = {
                customer: item.account,
                amount: 0,
                quantity: 0,
                discount: 0,
                totalAmount: 0,
                amountPaid: 0,
                balance: 0
            };
        }

        // Aggregate data for each customer
        acc[customerId].amount += parseFloat(item.amount) || 0;
        acc[customerId].quantity += parseFloat(item.quantity) || 0;
        acc[customerId].discount += parseFloat(item.discount) || 0;
        acc[customerId].totalAmount += parseFloat(item.total_amount) || 0;
        acc[customerId].amountPaid += parseFloat(item.amount_paid) || 0;
        acc[customerId].balance += parseFloat(item.balance) || 0;


        return acc;
    }, {});
    const aggregatedItems = Object.values(groupedItems);



    const [analytic, setAnalytic] = useState(null);

    useEffect(() => {
        const fetchAnalytic = async () => {
            try {
                const response = await axiosInstance.get("/user/fetch-analytic");
                setAnalytic(response.data.data.data);
            } catch (error) {
                console.error("Error fetching analytic:", error);
            }
        };

        fetchAnalytic();
    }, []);

    // const sendInvoiceEmail = async (account_id) => {
    //     setIsGeneralLoading(true);

    //     try {
    //         // Trigger the email sending API
    //         await axiosInstance.post(
    //             `user/invoice_custom_service/send_fixed_custom_invoice_mail/${account_id}`
    //         );
    //         toast.success("Invoice email sent successfully!");
    //     } catch (error) {
    //         console.error("Error sending invoice email:", error);
    //         toast.error("Failed to send invoice email.");
    // } finally {
    //     setIsGeneralLoading(false);
    // }
    // };
    const sendInvoiceEmail = async (account_id) => {
        setIsGeneralLoading(true);

        try {
            // Send the percentage to the backend along with the invoice
            await axiosInstance.post(
                `user/invoice_custom_service/send_fixed_custom_invoice_mail/${account_id}`,
                { percentage: parseFloat(percentage) } // Assuming backend expects this format
            );
            toast.success("Invoice email sent successfully with discount applied!");
        } catch (error) {
            console.error("Error sending invoice email:", error);
            toast.error("Failed to send invoice email.");
        } finally {
            setIsGeneralLoading(false);
            setPercentageModalOpen(false); // Close modal after submission
            setPercentage(''); // Reset percentage input
        }
    };

return (
    <div className="manage-permissions">
        {/* <!-- start page title --> */}
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
                        Invoicing
                    </h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <Link to="/app">Invoicing</Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                style={{ color: "#c4281c" }}
                            >
                                Invoicing
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- end page title --> */}
        {isGeneralLoading && !searchQuery ? ( // Only show loader for general data fetching
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
            >
                <Oval
                    height={80}
                    width={80}
                    color="#FF0200"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        ) : (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="d-flex justify-content-end mb-4">
                                <button
                                    className="vehicle-button btn btn-primary"
                                    style={{ backgroundColor: "#c4281b", color: "#fff", border: "none" }}
                                    onClick={() => setShowManageModal(true)}
                                >
                                    <i className="mdi mdi-plus-circle-outline"></i> Add Invoice
                                </button>
                            </div>
                            <div className="row mb-3">
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-3 col-md-6">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                Payment Status&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                            </span>
                                        </div>
                                        <select
                                            className="form-select"
                                            value={statusFilter}
                                            onChange={handleStatusFilterChange}
                                        >
                                            <option value="">All Status</option>
                                            <option value="paid">Paid</option>
                                            <option value="unpaid">Unpaid</option>
                                            <option value="partly_paid">Partly Paid</option>
                                            <option value="due">Due</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                Payment Status&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                            </span>
                                        </div>
                                        <select
                                            className="form-select"
                                            value={type}
                                            onChange={handleTypeChange}
                                        >
                                            <option value="">--type--</option>
                                            <option value="custom">Fixed</option>
                                            <option value="service">Custom</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive-sm">
                                <table
                                    className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                                    id="itemsTable"
                                >
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Account Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Quanity</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Amount Paid</th>
                                            <th scope="col">balance</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {aggregatedItems.map((item, index) => (
                                            <tr key={item.customer.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.customer.company_name}</td>
                                                <td>{formatAmount(item.amount)}</td>
                                                <td>{formatAmount(item.quantity)}</td>
                                                <td>{formatAmount(item.discount)}</td>
                                                <td>{formatAmount(item.totalAmount)}</td>
                                                <td>{formatAmount(item.amountPaid)}</td>
                                                <td>{formatAmount(item.balance)}</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="mdi mdi-apps"></i>
                                                        </button>

                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link className="dropdown-item" onClick={() => handleShowViewInvoice(item.customer.id)}>
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    View invoice
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                {/* <Link className="dropdown-item" onClick={() => sendInvoiceEmail(item.customer.id)}>
                                                                    <i className="ri-mail-fill text-muted me-2 align-bottom"></i>
                                                                    {isGeneralLoading ? (
                                                                        <>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />{" "}
                                                                            sending...
                                                                        </>
                                                                    ) : (
                                                                        " Send Invoice Email"
                                                                    )}
                                                                </Link> */}
                                                                <Link className="dropdown-item" onClick={() => {
                                                                    setPercentageModalOpen(true);
                                                                    setCurrentAccountId(item.customer.id);
                                                                }}>
                                                                    <i className="ri-mail-fill text-muted me-2 align-bottom"></i>
                                                                    {isGeneralLoading ? (
                                                                        <>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />{" "}
                                                                            sending...
                                                                        </>
                                                                    ) : (
                                                                        " Send Invoice Email"
                                                                    )}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={() => handleShowPaymentHistory(item.customer.id)}>
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    View payment history
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={() => handleShowEditModal(item.customer.id)}>
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={() => handleShowPayment(item)}>
                                                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                                                    Add Payment
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={() => handleDelete(item.customer.id)}>
                                                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                                                    Delete
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end mt-5 mb-5">
                                    <div className="pagination-wrap hstack gap-2">
                                        <ul className="pagination listjs-pagination mb-0">
                                            {(pagination.links || []).map((link, index) => {
                                                const pageNumber = link.url
                                                    ? new URL(link.url).searchParams.get("page")
                                                    : null;

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`page-item ${link.active ? "active" : ""
                                                            }`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                            onClick={() =>
                                                                pageNumber &&
                                                                handlePageChange(Number(pageNumber))
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: link.label,
                                                            }}
                                                        ></button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {/* Modal Component */}
        <Modal show={percentageModalOpen} onHide={() => {
            setPercentageModalOpen(false);
            setCurrentAccountId(null); // Reset account ID when closing modal
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Upfront Percentage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="number"
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                    placeholder="Enter percentage"
                    min="0" max="100"
                    className="form-control"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setPercentageModalOpen(false);
                    setPercentage('');
                    setCurrentAccountId(null); // Reset when cancelling
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => sendInvoiceEmail(currentAccountId)}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Include the CreateManage modal */}
        {showManageModal && (
            <CreateInvoice
                show={showManageModal}
                closeModal={handleCloseManageModal}
                item={selectedItem}
                refreshData={handleRefresh}
            />
        )}

        {/* Include the EditModal */}
        {showEditModal && (
            <EditInvoicing
                showEdit={showEditModal}
                closeModal={handleCloseEditModal}
                invoiceData={selectedItem}
                refreshData={handleRefresh}
            />
        )}
        {/* Include the EditModal */}
        {showPayment && (
            <AddCustomServicePayment
                showPayment={showPayment}
                closeModal={handleClosePayment}
                itemData={selectedItem}
                refreshData={handleRefresh}
            />
        )}

        {showViewModal && (
            <ViewInvoice
                showView={showViewModal}
                closeModal={handleCloseViewModal}
                Invoice={selectedItem}
                refreshData={handleRefresh}
            />
        )}

        {showPaymentHistory && (
            <ViewCustomFixedPayHistory
                showHistoryView={showPaymentHistory}
                closeModal={handleCloseViewPaymentHistory}
                invoice_id={paymentHistories}
                refreshData={handleRefresh}
            />
        )}

    </div>
);
}
