import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import axiosInstance from "../../../../hooks/axiosInstance";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";

export default function EditManage({ showEdit, closeModal, refreshData, invoiceId }) {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [account, setAccount] = useState([]);
    const [originalAmount, setOriginalAmount] = useState("");

    const [formData, setFormData] = useState({
        account_id: "",
        category_id: "",
        sub_category_id: "",
        product_id: "",
        amount: "",
        start_date: "",
        notes: "",
        status: "",
        circle: "",
        discount: "",
        end_date: ""
    });

    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (invoiceId) {
            setFormData({
                account_id: invoiceId.account_id|| "", // Assuming account_id is directly available in invoiceId
                category_id: invoiceId.category_id || "",
                sub_category_id: invoiceId.sub_category_id || "",
                product_id: invoiceId.product_id || "",
                amount: invoiceId.amount || "",
                start_date: invoiceId.start_date || "",
                notes: invoiceId.notes || "",
                discount: invoiceId.discount || "",
                status: invoiceId.status || "",
                circle: invoiceId.circle || "",
                end_date: invoiceId.end_date || "",
            });
            setOriginalAmount(invoiceId.amount); // Store original amount
        }
    }, [invoiceId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);

            } catch (error) {
                toast.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/category/fetch');
                setCategories(response.data.data.category.data);

            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);


    // Fetch subcategories when category changes
    useEffect(() => {
        const fetchSubCategories = async () => {
            if (formData.category_id) {
                try {
                    const response = await axiosInstance.get(`/user/category/getSubCategory?category_id=${formData.category_id}`);
                    setSubCategories(response.data.data.subCategories);
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
            }
        };

        fetchSubCategories();
    }, [formData.category_id]);

    // Fetch products when subcategory changes
    useEffect(() => {
        const fetchProducts = async () => {
            if (formData.sub_category_id) {
                try {
                    const response = await axiosInstance.get(`/user/product/getProductBySubcategoryId?sub_category=${formData.sub_category_id}`);
                    setProducts(response.data.data.product);
                } catch (error) {
                    console.error("Error fetching products", error);
                }
            }
        };

        fetchProducts();
    }, [formData.sub_category_id]);

    const resetForm = () => {
        setFormData({
            account_id: "",
            category_id: "",
            sub_category_id: "",
            product_id: "",
            amount: "",
            discount:"",
            start_date: "",
            notes: "",
            circle: "",
            status: "",
            end_date: ""
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "amount") {
            // Remove any commas in the value to keep the raw number
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

        // Apply discount if provided
        // if (name === "discount") {
        //     const discountValue = parseFloat(value) || 0;
        //     if (discountValue === 0) {
        //         setFormData((prevFormData) => ({
        //             ...prevFormData,
        //             amount: originalAmount,
        //         }));
        //     } else {
        //         const discountedAmount = originalAmount - (originalAmount * (discountValue / 100));
        //         setFormData((prevFormData) => ({
        //             ...prevFormData,
        //             amount: discountedAmount.toFixed(2),
        //         }));
        //     }
        // }

        // Prefill amount if product is selected
        if (name === "product_id") {
            const selectedProduct = products.find((product) => product.id === Number(value));
            setFormData((prevFormData) => ({
                ...prevFormData,
                amount: selectedProduct ? selectedProduct.amount : "",
            }));
            setOriginalAmount(selectedProduct ? selectedProduct.amount : "");
        }

        // Calculate balance
        if (name === "amount_paid") {
            const balance = formData.amount - parseFloat(value || 0);
            setFormData((prevFormData) => ({
                ...prevFormData,
                balance: balance.toFixed(2),
            }));
        }
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const startDate = new Date(formData.start_date);
            const circleMonths = parseInt(formData.circle, 10); // Number of months to add

            // Add the circle months to start_date to calculate end_date
            const endDate = new Date(startDate.setMonth(startDate.getMonth() + circleMonths));

            // Update formData with end_date
            const updatedFormData = {
                ...formData,
                end_date: endDate.toISOString().split('T')[0] // Format the date as YYYY-MM-DD
            };
            console.log("Updated Form Data: ", updatedFormData);

            await axiosInstance.post(`/user/invoice/update_invoice/${invoiceId.id}`, updatedFormData);
            refreshData();
            handleClose();
            toast.success("Invoice updated successfully!");
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setIsLoading(false);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="EditInvoice">
            <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-lg">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Oval
                            className="d-flex justify-content-center align-items-center"
                            height={80}
                            width={80}
                            color="#FF0200"
                            visible={isLoading}
                            ariaLabel="oval-loading"
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                        <Row>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAccount">
                                    <div>
                                        <h6>Account Name</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Account"
                                        name="account_id"
                                        value={formData.account_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Account</option>
                                        {account.map((acc) => (
                                            <option key={acc.id} value={acc.id}>
                                                {acc.company_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.account_id && (
                                        <small className="text-danger my-1">{validationError.account_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCategory">
                                    <div>
                                        <h6>Category</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Category"
                                        name="category_id"
                                        value={formData.category_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.category_id && (
                                        <small className="text-danger my-1">{validationError.category_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formSubCategory">
                                    <div>
                                        <h6>Sub-category</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Sub-category"
                                        name="sub_category_id"
                                        value={formData.sub_category_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Sub-category</option>
                                        {subCategories.map((subCat) => (
                                            <option key={subCat.id} value={subCat.id}>
                                                {subCat.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.sub_category_id && (
                                        <small className="text-danger my-1">{validationError.sub_category_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formProduct">
                                    <div>
                                        <h6>Product</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Product"
                                        name="product_id"
                                        value={formData.product_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Product</option>
                                        {products.map((prod) => (
                                            <option key={prod.id} value={prod.id}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.product_id && (
                                        <small className="text-danger my-1">{validationError.product_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmount">
                                    <div>
                                        <h6>Amount</h6>
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        name="amount"
                                        value={formatAmount(formData.amount)}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.amount}
                                        style={{
                                            backgroundColor: '#f5f5f5',
                                            color: '#333',
                                            border: '1px solid #ccc',
                                            cursor: 'not-allowed',
                                        }}
                                        readOnly
                                    />
                                    {validationError.amount && (
                                        <small className="text-danger my-1">{validationError.amount[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formDiscount">
                                    <Form.Label>Discount (%)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="discount"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.discount}
                                    />
                                    {validationError.discount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.discount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formStartDate">
                                    <div>
                                        <h6>Start Date</h6>
                                    </div>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        required
                                    />
                                    {validationError.start_date && (
                                        <small className="text-danger my-1">{validationError.start_date[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <div>
                                        <h6> Cycles (Months)</h6>
                                    </div>
                                    <Form.Control
                                        type="number"
                                        placeholder="Circle (Months)"
                                        name="circle"
                                        value={formData.circle}
                                        onChange={handleChange}
                                        required
                                    />
                                    {validationError.circle && (
                                        <small className="text-danger my-1">{validationError.circle[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formEndDate">
                                    <div>
                                        <h6>End Date</h6>
                                    </div>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        value={formData.end_date}
                                        isInvalid={!!validationError.end_date}
                                        style={{
                                            backgroundColor: '#f5f5f5',
                                            color: '#333',
                                            border: '1px solid #ccc',
                                            cursor: 'not-allowed',
                                        }}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.status}
                                    >
                                        <option value="">Select status</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="paid">Paid</option>
                                        <option value="partly_paid">Partly Paid</option>
                                        <option value="due">Due</option>
                                    </Form.Select>
                                    {validationError.status && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.status[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formNotes">
                                    <div>
                                        <h6>Notes</h6>
                                    </div>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Additional Notes"
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    updating...
                                </>
                            ) : (
                                "Update"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
