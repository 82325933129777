import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";

export default function EditInvoice({ showEdit, closeModal, refreshData, invoiceData }) {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [customProducts, setCustomProducts] = useState([]);
    const [serviceProducts, setServiceProducts] = useState([]);
    const [account, setAccount] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch account data and invoices by account_id
    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const response = await axiosInstance.get("/user/invoice_custom_service/fetch_no_apge", {
                    params: {
                        account_id: invoiceData, // Fetch orders with the same order_code
                    },
                });
                setInvoices(response.data.data.invoices);
            } catch (error) {
                console.error("Error fetching invoices", error);
                toast.error("Error fetching invoices.");
            }
        };
        fetchInvoices();

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/user/category/fetch');
                setCategories(response.data.data.category.data);
            } catch (error) {
                console.error("Error fetching categories", error);
            }
        };
        fetchCategories();
    }, [invoiceData]);

    // Fetch products for custom type or service type
    useEffect(() => {
        invoices.forEach((invoice) => {
            if (invoice.type === "custom") {
                fetchCustomProducts();
            } else if (invoice.type === "service" && invoice.sub_category_id) {
                fetchProductsBySubCategory(invoice.sub_category_id);
            }
        });
    }, [invoices]);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);
            } catch (error) {
                toast.error("Error fetching accounts");
            }
        };
        fetchAccounts();
    }, []);

    const fetchCustomProducts = async () => {
        try {
            const response = await axiosInstance.get(`/user/custom_product/fetch_no_paginate`);
            setCustomProducts(response.data.data.products);
        } catch (error) {
            console.error("Error fetching custom products", error);
        }
    };

    const fetchProductsBySubCategory = async (subCategoryId) => {
        try {
            const response = await axiosInstance.get(`/user/product/getProductBySubcategoryId?sub_category=${subCategoryId}`);
            setServiceProducts(response.data.data.product);
        } catch (error) {
            console.error("Error fetching service products", error);
        }
    };

    const handleTypeChange = (e, index) => {
        const type = e.target.value;
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index
                    ? {
                        ...invoice,
                        type,
                        category_id: "",
                        sub_category_id: "",
                        product_id: "",
                        custom_product_id: "",
                        amount: "",
                        total_amount: "",
                    }
                    : invoice
            )
        );

        if (type === "custom") {
            fetchCustomProducts();
        } else if (type === "service") {
            setServiceProducts([]);
        }
    };

    // Prefetch subcategories for prefilled `category_id`
    useEffect(() => {
        const fetchSubCategoriesForPrefilled = async () => {
            // Loop through invoices to check for prefilled `category_id`
            for (const invoice of invoices) {
                if (invoice.type === "service" && invoice.category_id) {
                    try {
                        console.log("Fetching subcategories for prefilled category_id:", invoice.category_id);
                        const response = await axiosInstance.get(
                            `/user/category/getSubCategory?category_id=${invoice.category_id}`
                        );
                        setSubCategories((prev) => ({
                            ...prev,
                            [invoice.category_id]: response.data.data.subCategories,
                        }));
                    } catch (error) {
                        console.error("Error fetching subcategories for prefilled category", error);
                    }
                }
            }
        };

        if (invoices.length > 0) {
            fetchSubCategoriesForPrefilled();
        }
    }, [invoices]);

    // Handle category change with dynamic subcategories update
    const handleCategoryChange = (e, index) => {
        const categoryId = e.target.value;
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index ? { ...invoice, category_id: categoryId, sub_category_id: "", product_id: "" } : invoice
            )
        );

        if (categoryId) {
            const fetchSubCategories = async () => {
                try {
                    const response = await axiosInstance.get(`/user/category/getSubCategory?category_id=${categoryId}`);
                    setSubCategories((prev) => ({
                        ...prev,
                        [categoryId]: response.data.data.subCategories,
                    }));
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
            };
            fetchSubCategories();
        }
    };

    // Render subcategories based on the selected category
    const renderSubCategories = (categoryId) => {
        return (subCategories[categoryId] || []).map((subCategory) => (
            <option key={subCategory.id} value={subCategory.id}>
                {subCategory.name}
            </option>
        ));
    };

    const handleSubCategoryChange = (e, index) => {
        const subCategoryId = e.target.value;
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index ? { ...invoice, sub_category_id: subCategoryId, product_id: "" } : invoice
            )
        );

        if (subCategoryId) {
            fetchProductsBySubCategory(subCategoryId);
        }
    };

    const handleAccountChange = (selectedOption) => {
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice) => ({
                ...invoice,
                account_id: selectedOption ? selectedOption.value : "",
            }))
        );
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;

        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) => {
                if (i === index) {
                    const updatedInvoice = { ...invoice, [name]: value };

                    if (name === "product_id" && invoice.type === "service") {
                        const selectedProduct = serviceProducts.find((product) => product.id === Number(value));
                        if (selectedProduct) {
                            updatedInvoice.amount = selectedProduct.amount || "";
                        }
                    } else if (name === "custom_product_id" && invoice.type === "custom") {
                        const selectedProduct = customProducts.find((product) => product.id === Number(value));
                        if (selectedProduct) {
                            updatedInvoice.amount = selectedProduct.amount || "";
                        }
                    }

                    return updatedInvoice;
                }
                return invoice;
            })
        );
    };

    const calculateTotalAmount = (invoice) => {
        const quantity = invoice.quantity ? parseFloat(invoice.quantity) : 1;
        const discount = invoice.discount ? parseFloat(invoice.discount) : 0;
        let amount = parseFloat(invoice.amount) || 0;

        if (discount > 0) {
            amount -= amount * (discount / 100);
        }

        return (amount * quantity).toFixed(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                account_id: invoices[0].account_id,
                orders: invoices.map((invoice) => ({
                    id: invoice.id,
                    product_id: invoice.type === "service" ? invoice.product_id || "" : "",
                    custom_product_id: invoice.type === "custom" ? invoice.custom_product_id || "" : "",
                    category_id: invoice.type === "service" ? invoice.category_id || "" : "",
                    sub_category_id: invoice.type === "service" ? invoice.sub_category_id || "" : "",
                    price: parseFloat(invoice.amount || 0).toFixed(2),
                    quantity: parseInt(invoice.quantity || 1, 10),
                    discount: parseFloat(invoice.discount || 0).toFixed(2),
                    total_price: calculateTotalAmount(invoice),
                    type: invoice.type || "",
                })),
            };

            await axiosInstance.post('/user/invoice_custom_service/update_invoice', payload);
            refreshData();
            handleClose();
            toast.success("Invoice updated successfully!");
        } catch (error) {
            console.error("Error updating invoice", error);
            toast.error("Error updating invoice.");
        }

        setIsLoading(false);
    };

    const handleClose = () => {
        closeModal();
    };

    const accountOptions = account.map((acc) => ({
        value: acc.id,
        label: acc.company_name,
    }));

    return (
        <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-lg">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Account Field */}
                    <Form.Group className="mb-3" controlId="formAccount">
                        <h6>Account Name</h6>
                        <Select
                            options={accountOptions}
                            value={accountOptions.find(option => option.value === invoices[0]?.account_id)}
                            onChange={handleAccountChange}
                            placeholder="Search Account"
                        />
                    </Form.Group>
                    {invoices.map((invoice, index) => (
                        <div key={index} className="mb-2 p-3" style={{ border: '1px dotted rgba(0, 0, 0, 0.5)' }}>
                            <button className="btn btn-info d-flex justify-content-center mx-auto mt-1 mb-4" disabled>Invoice {index + 1}</button>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId={`formType-${index}`}>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select
                                            name="type"
                                            value={invoice.type}
                                            onChange={(e) => handleTypeChange(e, index)}
                                        >
                                            <option value="">--select--</option>
                                            <option value="service">Fixed</option>
                                            <option value="custom">Custom</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                {/* Category and SubCategory for service */}
                                {invoice.type === "service" && (
                                    <>
                                        <Col md={6}>
                                            <Form.Group controlId={`formCategory-${index}`}>
                                                <Form.Label>Category</Form.Label>
                                                <Form.Select
                                                    name="category_id"
                                                    value={invoice.category_id}
                                                    onChange={(e) => handleCategoryChange(e, index)}
                                                >
                                                    <option value="">--Select Category--</option>
                                                    {categories.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId={`formSubCategory-${index}`}>
                                                <Form.Label>Sub Category</Form.Label>
                                                <Form.Select
                                                    name="sub_category_id"
                                                    value={invoice.sub_category_id}
                                                    onChange={(e) => handleSubCategoryChange(e, index)}
                                                >
                                                    <option value="">--Select Sub Category--</option>
                                                    {renderSubCategories(invoice.category_id)}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId={`formProduct-${index}`}>
                                                <Form.Label>Product</Form.Label>
                                                <Form.Select
                                                    name="product_id"
                                                    value={invoice.product_id}
                                                    onChange={(e) => handleChange(e, index)}
                                                >
                                                    <option value="">--Select Product--</option>
                                                    {(invoice.type === "custom" ? customProducts : serviceProducts).map((product) => (
                                                        <option key={product.id} value={product.id}>
                                                            {product.product_name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>


                                        {/* Amount */}
                                        <Col md={6} className="mt-3">
                                            <Form.Group controlId={`formAmount-${index}`}>
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="amount"
                                                    value={invoice.amount || 0}
                                                    onChange={(e) => handleChange(e, index)}
                                                    step="0.01"
                                                    style={{
                                                        backgroundColor: '#f5f5f5',
                                                        color: '#333',
                                                        border: '1px solid #ccc',
                                                        cursor: 'not-allowed',
                                                    }}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )}
                                {/* Product */}
                                {invoice.type === "custom" && (
                                    <>
                                <Col md={6} className="mt-3">
                                    <Form.Group controlId={`formProduct-${index}`}>
                                        <Form.Label>Product</Form.Label>
                                        <Form.Select
                                            name="custom_product_id"
                                            value={invoice.custom_product_id}
                                            onChange={(e) => handleChange(e, index)}
                                        >
                                            <option value="">--Select Product--</option>
                                            {(invoice.type === "custom" ? customProducts : serviceProducts).map((product) => (
                                                <option key={product.id} value={product.id}>
                                                    {product.product_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                {/* Amount */}
                                

                                <Col md={6} className="mt-3">
                                    <Form.Group controlId={`formAmount-${index}`}>
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="amount"
                                            value={invoice.amount || ""}
                                            onChange={(e) => handleChange(e, index)}
                                            step="0.01"
                                        />
                                    </Form.Group>
                                        </Col>
                                        </>
                                )}

                                {/* Quantity */}
                                <Col md={6} className="mt-3">
                                    <Form.Group controlId={`formQuantity-${index}`}>
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="quantity"
                                            value={invoice.quantity || ""}
                                            onChange={(e) => handleChange(e, index)}
                                            min="1"
                                        />
                                    </Form.Group>
                                </Col>
                              
                                {/* Discount */}
                                <Col md={6} className="mt-3">
                                    <Form.Group controlId={`formDiscount-${index}`}>
                                        <Form.Label>Discount (%)</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="discount"
                                            value={invoice.discount || 0}
                                            onChange={(e) => handleChange(e, index)}
                                            min="0"
                                            max="100"
                                        />
                                    </Form.Group>
                                </Col>
                                {/* Total Amount */}
                                <Col md={6} className="mt-3">
                                    <Form.Group controlId={`formTotalAmount-${index}`}>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={calculateTotalAmount(invoice)}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" disabled={isLoading}>
                        {isLoading ? "Updating..." : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
