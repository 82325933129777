import { useState, useEffect } from "react";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";

export default function AddPayment({ showPayment, closeModal, refreshData, itemData }) {
    const [formData, setFormData] = useState({
        invoice_id: itemData.id,
        product: itemData?.product.product_name || "",
        balance: itemData?.balance || "",
        date_of_payment: "",
        amount_paid: "" // Raw value without commas
    });
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (itemData) {
            setFormData({
                invoice_id: itemData.id,
                product: itemData.product.product_name || "",
                balance: itemData.balance || "",
                date_of_payment: "",
                amount_paid: ""
            });
        }
    }, [itemData]);

    const resetForm = () => {
        setFormData({
            invoice_id: "",
            product: "",
            balance: "",
            date_of_payment: "",
            amount_paid: "",
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Check if it's amount_paid and remove commas if present
        if (name === "amount_paid") {
            // Remove any commas in the value to keep the raw number
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Simulate form submission
            await axiosInstance.post('/user/payment/create_fixed_payment', formData);
            handleClose();
            refreshData();
            toast.success("Payment added successfully!");
        } catch (error) {
            console.error("Error submitting form:", error);
            // Check if the error message is related to the remaining balance
            if (error.response && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error("Failed to add payment.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Function to format number with commas
    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="AddPayment">
            <Modal show={showPayment} onHide={handleClose} dialogClassName="modal-md">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {parseFloat(formData.balance) > 0 ? (
                        <Row>
                            <Col md={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formProduct">
                                    <Form.Label>Product</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="product"
                                        value={formData.product}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmount">
                                    <Form.Label>Balance</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="balance"
                                        value={formData.balance}
                                        isInvalid={!!validationError.balance}
                                        style={{
                                            backgroundColor: '#f5f5f5',
                                            color: '#333',
                                            border: '1px solid #ccc',
                                            cursor: 'not-allowed',
                                        }}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formDate">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Select date"
                                        name="date_of_payment"
                                        value={formData.date_of_payment}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.date_of_payment}
                                    />
                                    {validationError.date_of_payment && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.date_of_payment[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmountToPay">
                                    <Form.Label>Amount to pay</Form.Label>
                                    <Form.Control
                                        type="text" // Use text to allow formatting with commas
                                        placeholder="Enter amount"
                                        name="amount_paid"
                                        value={formatAmount(formData.amount_paid)} // Display formatted value
                                        onChange={handleChange}
                                        isInvalid={!!validationError.amount_paid}
                                    />
                                    {validationError.amount_paid && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.amount_paid[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            </Row>
                        ) : (
                            <p style={{ color: "red", textAlign: "center" }}>
                                This invoice has a balance of <strong>0</strong>, no payment is needed.
                            </p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
