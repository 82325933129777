import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";

export default function CreateInvoice({ show, closeModal, refreshData }) {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [customProducts, setCustomProducts] = useState([]);
    const [serviceProducts, setServiceProducts] = useState([]);
    const [account, setAccount] = useState([]);
    const [originalAmount, setOriginalAmount] = useState("");

    const [invoices, setInvoices] = useState([

        {
            account_id: "",
            product_id: "",
            custom_product_id: "",
            amount: "",
            quantity: 1,
            discount: "",
            type: "",
            total_amount: "",
            category_id: "",
            sub_category_id: "",
            status: "",
        },
    ]);
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Fetch accounts
    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);
            } catch (error) {
                toast.error("Error fetching accounts");
            }
        };
        fetchAccounts();
    }, []);

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/user/category/fetch');
                setCategories(response.data.data.category.data);
            } catch (error) {
                console.error("Error fetching categories", error);
            }
        };
        fetchCategories();
    }, []);

    // Monitor invoice changes to fetch appropriate products
    useEffect(() => {
        invoices.forEach((invoice) => {
            if (invoice.type === "custom") {
                fetchCustomProducts();
            } else if (invoice.type === "service" && invoice.sub_category_id) {
                fetchProductsBySubCategory(invoice.sub_category_id);
            }
        });
    }, [invoices]);

    const fetchCustomProducts = async () => {
        try {
            const response = await axiosInstance.get(`/user/custom_product/fetch_no_paginate`);
            setCustomProducts(response.data.data.products);
        } catch (error) {
            console.error("Error fetching custom products", error);
        }
    };

    const fetchProductsBySubCategory = async (subCategoryId) => {
        try {
            const response = await axiosInstance.get(`/user/product/getProductBySubcategoryId?sub_category=${subCategoryId}`);
            setServiceProducts(response.data.data.product);
        } catch (error) {
            console.error("Error fetching service products", error);
        }
    };

    const handleTypeChange = (e, index) => {
        const type = e.target.value;

        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index
                    ? {
                        ...invoice,
                        type,
                        product_id: "",
                        custom_product_id: "",
                        category_id: "",
                        sub_category_id: "",
                        amount: "",
                        total_amount: "",
                    }
                    : invoice
            )
        );

        if (type === "custom") {
            fetchCustomProducts();
        } else if (type === "service") {
            setServiceProducts([]);
        }
    };


    const handleAccountChange = (selectedOption) => {
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice) => ({
                ...invoice,
                account_id: selectedOption ? selectedOption.value : "",
            }))
        );
    };
    const handleRemoveInvoiceForm = (index) => {
        setInvoices((prevInvoices) => prevInvoices.filter((_, i) => i !== index));
    };


    const handleCategoryChange = (e, index) => {
        const categoryId = e.target.value;
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index ? { ...invoice, category_id: categoryId, sub_category_id: "", product_id: "" } : invoice
            )
        );

        if (categoryId) {
            const fetchSubCategories = async () => {
                try {
                    const response = await axiosInstance.get(`/user/category/getSubCategory?category_id=${categoryId}`);
                    setSubCategories(response.data.data.subCategories);
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
            };
            fetchSubCategories();
        } else {
            setSubCategories([]);
        }
    };

    const handleSubCategoryChange = (e, index) => {
        const subCategoryId = e.target.value;
        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) =>
                i === index ? { ...invoice, sub_category_id: subCategoryId, product_id: "" } : invoice
            )
        );

        if (subCategoryId) {
            fetchProductsBySubCategory(subCategoryId);
        }
    };
    // const handleChange = (e, index) => {
    //     const { name, value } = e.target;

    //     setInvoices((prevInvoices) =>
    //         prevInvoices.map((invoice, i) => {
    //             if (i === index) {
    //                 const updatedInvoice = { ...invoice, [name]: value };

    //                 if (name === "product_id") {
    //                     // Check selected type and fetch the correct product
    //                     const selectedProduct =
    //                         invoice.type === "custom"
    //                             ? customProducts.find((product) => product.id === Number(value))
    //                             : serviceProducts.find((product) => product.id === Number(value));

    //                     if (selectedProduct) {
    //                         updatedInvoice.amount = selectedProduct.amount || "";
    //                     }
    //                 }

    //                 return updatedInvoice;
    //             }
    //             return invoice;
    //         })
    //     );
    // };

    const handleChange = (e, index) => {
        const { name, value } = e.target;

        setInvoices((prevInvoices) =>
            prevInvoices.map((invoice, i) => {
                if (i === index) {
                    const updatedInvoice = { ...invoice, [name]: value };

                    if (name === "product_id" && invoice.type === "service") {
                        const selectedProduct = serviceProducts.find((p) => p.id === Number(value));
                        if (selectedProduct) {
                            updatedInvoice.amount = selectedProduct.amount || "";
                            updatedInvoice.custom_product_id = ""; // Ensure custom field is cleared
                        }
                    } else if (name === "custom_product_id" && invoice.type === "custom") {
                        const selectedProduct = customProducts.find((p) => p.id === Number(value));
                        if (selectedProduct) {
                            updatedInvoice.amount = selectedProduct.amount || "";
                            updatedInvoice.product_id = ""; // Ensure service field is cleared
                        }
                    }

                    return updatedInvoice;
                }
                return invoice;
            })
        );
    };



    const calculateTotalAmount = (invoice) => {
        const quantity = invoice.quantity ? parseFloat(invoice.quantity) : 1;
        const discount = invoice.discount ? parseFloat(invoice.discount) : 0;
        let amount = parseFloat(invoice.amount) || 0;

        if (discount > 0) {
            amount -= amount * (discount / 100);
        }

        return (amount * quantity).toFixed(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                account_id: invoices[0].account_id,
                orders: invoices.map((invoice) => ({
                    product_id: invoice.type === "service" ? invoice.product_id || "" : "",
                    custom_product_id: invoice.type === "custom" ? invoice.custom_product_id || "" : "",
                    category_id: invoice.type === "service" ? invoice.category_id || "" : "",
                    sub_category_id: invoice.type === "service" ? invoice.sub_category_id || "" : "",
                    price: parseFloat(invoice.amount || 0).toFixed(2),
                    quantity: parseInt(invoice.quantity || 1, 10),
                    discount: parseFloat(invoice.discount || 0).toFixed(2),
                    total_price: calculateTotalAmount(invoice),
                    type: invoice.type || "",
                })),
            };

            await axiosInstance.post('/user/invoice_custom_service/create', payload);
            refreshData();
            handleClose();
            toast.success("Invoices added successfully!");
        } catch (error) {
            console.error("Error submitting invoices", error);
            toast.error("Error creating invoices.");
        }

        setIsLoading(false);
    };

    const handleClose = () => {
        setInvoices([
            {
                account_id: "",
                product_id: "",
                custom_product_id: "",
                amount: "",
                quantity: 1,
                discount: "",
                type: "",
                total_amount: "",
                category_id: "",
                sub_category_id: "",
                status: "",
            },
        ]);
        setValidationError({});
        closeModal();
    };

    const accountOptions = account.map((acc) => ({
        value: acc.id,
        label: acc.company_name,
    }));

    const handleAddInvoiceForm = () => {
        setInvoices((prevInvoices) => [
            ...prevInvoices,
            {
                account_id: "",
                product_id: "",
                custom_product_id:"",
                amount: "",
                quantity: 1,
                discount: "",
                type: "",
                total_amount: "",
                category_id: "",
                sub_category_id: "",
                status: "",
            },
        ]);
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Customer Field */}
                    <Form.Group className="mb-3" controlId="formAccount">
                        <h6>Account Name</h6>
                        <Select
                            options={accountOptions}
                            value={accountOptions.find(option => option.value === invoices[0].account_id)}
                            onChange={handleAccountChange}
                            placeholder="Search Account"
                            required
                        />
                        {validationError.account_id && (
                            <small className="text-danger my-1">{validationError.account_id[0]}</small>
                        )}
                    </Form.Group>
                    {invoices.map((invoice, index) => (
                        <div className="mb-2 p-3" style={{ border: '1px dotted rgba(0, 0, 0, 0.5)' }}>
                            <button className="btn btn-info d-flex justify-content-center mx-auto mt-1 mb-4" disabled>Invoice {index + 1}</button>
                            <div key={index} className="invoice-form mb-4">
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId={`formType-${index}`}>
                                            <Form.Label>Type</Form.Label>
                                            <Form.Select
                                                name="type"
                                                value={invoice.type}
                                                onChange={(e) => handleTypeChange(e, index)}
                                                required
                                            >
                                                <option value="">--select--</option>
                                                <option value="service">Fixed</option>
                                                <option value="custom">Custom</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    {/* Conditional rendering based on type */}
                                    {invoice.type === "service" && (
                                        <>
                                            <Col md={6}>
                                                <Form.Group controlId={`formCategory-${index}`}>
                                                    <Form.Label>Category</Form.Label>
                                                    <Form.Select
                                                        name="category_id"
                                                        value={invoice.category_id}
                                                        onChange={(e) => handleCategoryChange(e, index)}
                                                        required
                                                    >
                                                        <option value="">--Select Category--</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-2 mt-2">
                                                <Form.Group className="mb-3" controlId="formSubCategory">
                                                    <Form.Label>Subcategory</Form.Label>
                                                    <Form.Select
                                                        name="sub_category_id"
                                                        value={invoice.sub_category_id}
                                                        onChange={(e) => handleSubCategoryChange(e, index)}
                                                        required
                                                    >
                                                        <option value="">--Select Subcategory--</option>
                                                        {subCategories.map((subCategory) => (
                                                            <option key={subCategory.id} value={subCategory.id}>
                                                                {subCategory.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            {/* Product field for service */}
                                            {invoice.sub_category_id && (
                                                <Col md={6} className="mb-2 mt-2">
                                                    <Form.Group className="mb-3" controlId="formProduct">
                                                        <Form.Label>Product</Form.Label>
                                                        <Form.Select
                                                            name="product_id"
                                                            value={invoice.product_id}
                                                            onChange={(e) => handleChange(e, index)}
                                                            required
                                                        >
                                                            <option value="">--Select Product--</option>
                                                            {serviceProducts.map((product) => (
                                                                <option key={product.id} value={product.id}>
                                                                    {product.product_name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )}

                                            <Col md={6} className="mt-2">
                                                <Form.Group controlId={`formAmount-${index}`}>
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="amount"
                                                        value={invoice.amount || originalAmount || ""}
                                                        onChange={(e) => handleChange(e, index)}
                                                        required
                                                        style={{
                                                            backgroundColor: '#f5f5f5',
                                                            color: '#333',
                                                            border: '1px solid #ccc',
                                                            cursor: 'not-allowed',
                                                        }}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}

                                    {invoice.type === "custom" && (
                                        <>
                                            <Col md={6} className="mb-2">
                                                <Form.Group className="mb-3" controlId="formProduct">
                                                    <Form.Label>Product</Form.Label>
                                                    <Form.Select
                                                        name="custom_product_id"
                                                        value={invoice.custom_product_id}
                                                        onChange={(e) => handleChange(e, index)}
                                                        required
                                                    >
                                                        <option value="">--Select Product--</option>
                                                        {customProducts.map((product) => (
                                                            <option key={product.id} value={product.id}>
                                                                {product.product_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6} className="mt-2">
                                                <Form.Group controlId={`formAmount-${index}`}>
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="amount"
                                                        value={invoice.amount || originalAmount || ""}
                                                        onChange={(e) => handleChange(e, index)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>

                                    )}

                                    {/* Common Fields */}


                                    <Col md={6}>
                                        <Form.Group controlId={`formQuantity-${index}`}>
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="quantity"
                                                value={invoice.quantity}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId={`formDiscount-${index}`}>
                                            <Form.Label>Discount (%)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="discount"
                                                value={invoice.discount}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId={`formTotal-${index}`}>
                                            <Form.Label>Total Amount</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={`₦${calculateTotalAmount(invoice)}`}
                                                readOnly
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* Remove Button */}
                                <div className="d-flex justify-content-end text-end">

                                    <Col xs={12} className="mb-3">
                                        {index > 0 && (
                                            <Button variant="danger" onClick={() => handleRemoveInvoiceForm(index)}>
                                                <i className="ri-delete-bin-line"></i>
                                            </Button>
                                        )}
                                    </Col>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" onClick={handleAddInvoiceForm} style={{ fontWeight: "900", fontSize: "15px" }}>
                            <i className="ri-add-line"></i> Add Invoice
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? "Saving..." : "Save"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
