import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import axiosInstance from "../../../../hooks/axiosInstance";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";


export default function CreateManage({ show, closeModal, refreshData }) {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [account, setAccount] = useState([]);
    const [originalAmount, setOriginalAmount] = useState("");

    const [formData, setFormData] = useState({
        account_id: "",
        category_id: "",
        sub_category_id: "",
        product_id: "",
        amount: "",
        start_date: "",
        notes: "",
        status: "",
        circle: ""

    });
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Fetch categories, units of measure, and groups
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);

            } catch (error) {
                toast.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/category/fetch');
                setCategories(response.data.data.category.data);

            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);

    // Fetch subcategories when category changes
    useEffect(() => {
        const fetchSubCategories = async () => {
            if (formData.category_id) {
                try {
                    const response = await axiosInstance.get(`/user/category/getSubCategory?category_id=${formData.category_id}`);
                    setSubCategories(response.data.data.subCategories);
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
            }
        };

        fetchSubCategories();
    }, [formData.category_id]);

    useEffect(() => {
        const fetchProducts = async () => {
            if (formData.sub_category_id) {
                try {
                    const response = await axiosInstance.get(`/user/product/getProductBySubcategoryId?sub_category=${formData.sub_category_id}`);
                    setProducts(response.data.data.product);
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
            }
        };

        fetchProducts();
    }, [formData.sub_category_id]);

    const resetForm = () => {
        setFormData({
            account_id: "",
            category_id: "",
            sub_category_id: "",
            product_id: "",
            amount: "",
            start_date: "",
            notes: "",
            circle:"",
            status: ""

        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "amount") {
            // Remove any commas in the value to keep the raw number
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

        // // Apply discount if provided
        // if (name === "discount") {
        //     const discountValue = parseFloat(value) || 0;
        //     if (discountValue === 0) {
        //         setFormData((prevFormData) => ({
        //             ...prevFormData,
        //             amount: originalAmount,
        //         }));
        //     } else {
        //         const discountedAmount = originalAmount - (originalAmount * (discountValue / 100));
        //         setFormData((prevFormData) => ({
        //             ...prevFormData,
        //             amount: discountedAmount.toFixed(2),
        //         }));
        //     }
        // }


        // Prefill amount if product is selected
        if (name === "product_id") {
            const selectedProduct = products.find((product) => product.id === Number(value));
            setFormData((prevFormData) => ({
                ...prevFormData,
                amount: selectedProduct ? selectedProduct.amount : "",
            }));
            setOriginalAmount(selectedProduct ? selectedProduct.amount : "");
        }


        // Calculate balance
        if (name === "amount_paid") {
            const balance = formData.amount - parseFloat(value || 0);
            setFormData((prevFormData) => ({
                ...prevFormData,
                balance: balance.toFixed(2),
            }));
        }
    };

    // Handle account selection change
    const handleAccountChange = (selectedOption) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: selectedOption ? selectedOption.value : "",
        }));
    };
    const accountOptions = account.map((acc) => ({
        value: acc.id,
        label: acc.company_name
    }))


    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const startDate = new Date(formData.start_date);
            const circleMonths = parseInt(formData.circle, 10); // Number of months to add

            // Add the circle months to start_date to calculate end_date
            const endDate = new Date(startDate.setMonth(startDate.getMonth() + circleMonths));

            // Update formData with end_date
            const updatedFormData = {
                ...formData,
                end_date: endDate.toISOString().split('T')[0] // Format the date as YYYY-MM-DD
            };
            console.log("Updated Form Data: ", updatedFormData);

            await axiosInstance.post('/user/invoice/create_invoice', updatedFormData);
            refreshData();
            handleClose();
            toast.success("Invoice added successfully!");
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setIsLoading(false);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="AddFixedInvoice">
            <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Fixed Invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/* <Col md={6} lg={6} xxs={12} sm={12} className="mb-2"> */}
                            <Form.Group className="mb-3" controlId="formAccount">
                                <div>
                                    <h6>Account Name</h6>
                                </div>
                                <Select
                                    options={accountOptions}
                                    value={accountOptions.find(option => option.value === formData.account_id)}
                                    onChange={handleAccountChange}
                                    placeholder="Search Account"
                                />
                                {validationError.account_id && (
                                    <small className="text-danger my-1">{validationError.account_id[0]}</small>
                                )}
                            </Form.Group>
                            {/* </Col> */}
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCategory">
                                    <div>
                                        <h6>Category</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Category"
                                        name="category_id"
                                        value={formData.category_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.category_id && (
                                        <small className="text-danger my-1">{validationError.category_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formSubCategory">
                                    <div>
                                        <h6>Sub-category</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Sub-category"
                                        name="sub_category_id"
                                        value={formData.sub_category_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Sub-category</option>
                                        {subCategories.map((subCat) => (
                                            <option key={subCat.id} value={subCat.id}>
                                                {subCat.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.sub_category_id && (
                                        <small className="text-danger my-1">{validationError.sub_category_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formProduct">
                                    <Form.Label>Product</Form.Label>
                                    <Form.Select
                                        name="product_id"
                                        value={formData.product_id}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.product}
                                    >
                                        <option value="">--select product--</option>
                                        {products.map((product) => (
                                            <option key={product.id} value={product.id}>
                                                {product.product_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.product && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.product[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter amount"
                                        name="amount"
                                        value={formatAmount(formData.amount)}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.amount}
                                        style={{
                                            backgroundColor: '#f5f5f5',  
                                            color: '#333',              
                                            border: '1px solid #ccc',    
                                            cursor: 'not-allowed',    
                                        }}
                                        readOnly
                                    />
                                    {validationError.amount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.amount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.start_date}
                                    />
                                    {validationError.start_date && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.start_date[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formDiscount">
                                    <Form.Label>Discount (%)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="discount"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.discount}
                                    />
                                    {validationError.discount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.discount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Cycles (months)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="circle"
                                        value={formData.circle}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.circle}
                                    />
                                    {validationError.circle && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.circle[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.status}
                                    >
                                        <option value="">Select status</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="paid">Paid</option>
                                        <option value="partly_paid">Partly Paid</option>
                                        <option value="due">Due</option>
                                    </Form.Select>
                                    {validationError.status && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.status[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formNotes">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter notes"
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.notes}
                                    />
                                    {validationError.notes && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.notes[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
